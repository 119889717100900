import React from "react";

function PageNotFound() {
    return (
        <h1>
            403
        </h1>
    );
}

export default PageNotFound;