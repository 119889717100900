import React from "react";

function Main() {
    return (
        <h1>
            dashboard
        </h1>
    );
}

export default Main;