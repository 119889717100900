import React from "react";

function About() {
    return (
        <h1>
            about
        </h1>
    );
}

export default About;