import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    'firstname': 'Alpha',
    'role': '00b4fb28-6b78-4a7b-88f4-492cc2cfedad'
  });
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    if (user) {
      return;
    }

    try {
      const decodedToken = jwtDecode(localStorage.getItem("token"));
      setUser(decodedToken.data);
    } catch (error) {
      navigate("/signin");
    }

  }, [token]);

  return (
    <AuthContext.Provider value={{user}} >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };