import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from "./components/protected_route";
import PageNotFound from "./pages/page_not_found";
import { AuthProvider } from './contexts/auth';
import "./index.css";

function AuthenticatedRoutes() {
  return (
    <AuthProvider>
      <Routes>
          <Route path="/" element={<ProtectedRoute />} />
          <Route path="/about" element={<ProtectedRoute />} />
          <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AuthProvider>
  );
}

const App = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
          <Routes>
          <Route path="/signin" element={<h1>sign in</h1>} />
            <Route path="/403" element={<PageNotFound />} />
            <Route path="*" element={<AuthenticatedRoutes />} />
          </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
