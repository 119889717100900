import React from "react";

function Profile() {
    return (
        <h1>
            profile
        </h1>
    );
}

export default Profile;