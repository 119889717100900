import {
  ArrowsRightLeftIcon,
  BuildingStorefrontIcon,
  ChartPieIcon,
  CogIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import Dashboard from "./dashboard";
import Profile from "./profile";
import About from "./about";

const DefaultViews = {
  "/profile": {
    label: "Settings",
    component: <Profile />,
    icon: CogIcon,
  },
};

const RoleBasedViews = {
  "00b4fb28-6b78-4a7b-88f4-492cc2cfedad": {
    name: "cheche_manager",
    routes: {
      "/": {
        label: "Dashboard",
        component: <Dashboard />,
        icon: ChartPieIcon,
      },
      "/about": {
        label: "About",
        component: <About />,
        icon: CogIcon,
      },
    },
  },
  "00b4fb28-6b78-4a7b-88f4-492cc2cfedad": {
    name: "cheche_manager",
    routes: {
      "/": {
        label: "Dashboard",
        component: <Dashboard />,
        icon: ChartPieIcon,
      },
    },
  },
};

export { DefaultViews, RoleBasedViews };
